import './disclaimer.css';

import { CustomModalContent } from '../';
import { useDisclaimerContext } from '../../lib';
import { useState } from 'react';

function Disclaimer() {
  const { disclaimerContext } = useDisclaimerContext();
  const { privacyPolicy, termsOfUse } = disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    privacyPolicy: false,
    optOut: false,
  });
  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  const year = new Date().getFullYear();

  const click = (event) => {
    if (event.target?.id === 'ppTag') {
      setIsOpen({
        privacyPolicy: true,
        termsOfUse: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.target?.id === 'touTag') {
      setIsOpen({
        privacyPolicy: false,
        termsOfUse: true,
      });
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <div className="disclaimer__container">
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        isHtml
        clickEvent={click}
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        isHtml
        clickEvent={click}
      />

      <div className="disclaimer__menu">
        <div className="disclaimer__copyright disclaimer__copyright-hidden">
          &copy; {year} 24hourwindows, All rights reserved.
        </div>
        <div className="disclaimer__menu-options">
          <button
            className="disclaimer__button-as-link"
            onClick={() => openModal('privacyPolicy')}
          >
            Privacy Policy
          </button>
          <button
            className="disclaimer__button-as-link"
            onClick={() => openModal('termsOfUse')}
          >
            Terms of Use
          </button>
          <button
            className="disclaimer__button-as-link"
            onClick={() =>
              window.open(process.env.REACT_APP_DO_NOT_SELL_MY_INFO, '_blank')
            }
          >
            Do Not Sell My Info
          </button>
          <button
            className="disclaimer__button-as-link"
            onClick={() => window.open(process.env.REACT_APP_OPT_OUT, '_blank')}
          >
            Opt Out
          </button>
        </div>
        <div className="disclaimer__menu-text">
          24hourwindows.com an RGR Marketing website provides marketing services
          for various companies involved in various home improvement projects.
          The information you provide to us is provided to these companies in
          real time. If the information you provide to us matches the criteria
          they are seeking, they will contact you directly. In many cases we may
          deploy SMS to you to facilitate an inbound call to the service or
          product provider. We are paid by such providers for each consumer they
          contact directly and/or provide services or products. You are not
          charged for our services. We do not guarantee that a provider will
          accept your request or that their products or services will meet your
          needs. Their services and products may or may not be the best product
          or service available on the market. Completing our forms does not
          obligate you to purchase a service or product nor does it obligate a
          product or service provider to provide you with any particular service
          about which you may have inquired. We only accept referrals for U.S.
          Citizens on this Website and we specifically exclude all other
          countries including but not limited to Canadian and European Union
          Member Citizens referrals.
        </div>
      </div>
      <div className="disclaimer__copyright">
        &copy; {year} 24hourwindows, All rights reserved.
      </div>
    </div>
  );
}

export default Disclaimer;
