import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useConditionalNavigation = (condition, targetPath) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (condition) {
      navigate(targetPath);
    }
  }, [condition, navigate, targetPath]);
};

export default useConditionalNavigation;
