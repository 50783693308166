import {
  ZIP_CODE_ERROR_MESSAGE,
  ZIP_CODE_REGEX,
  debounceUpdate,
  handleKeyDown,
  isZipCodeValid,
  onlyNumbersInput,
  useFormDataContext,
  useScrollToTop,
} from '../../../lib';
import { useRef, useState } from 'react';

import { ThreeDots } from 'react-loader-spinner';
import { useFormContext } from 'react-hook-form';

function FormStep2() {
  const zipRef = useRef();
  const [isValidatingZip, setIsValidatingZip] = useState(false);
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    formState,
    setError,
    clearErrors,
  } = useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  const { errors } = formState;

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['zip']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        zip: zipRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  const handleUpdateZipCode = async (data) => {
    setIsValidatingZip(true);
    const { error, ...resultData } = await isZipCodeValid(data.zip);
    updateFormData(resultData);

    if (error) {
      setError('zip', { message: ZIP_CODE_ERROR_MESSAGE });
    } else {
      clearErrors('zip');
      setValue('city', resultData.city);
      setValue('state', resultData.state);
      setValue('zip', resultData.zip);
    }
    setIsValidatingZip(false);
  };

  useScrollToTop();
  return (
    <div className="form-step">
      <div className="form-step__label">What is the property zip code?</div>
      <div className="form-step__input-container">
        <input
          type="text"
          placeholder="Please enter ZIP code"
          ref={zipRef}
          onKeyDown={handleKeyDown}
          className={`form-step__input ${
            errors.zip && 'form-step__input-error'
          }`}
          maxLength="5"
          inputMode="numeric"
          onInput={onlyNumbersInput}
          autoComplete="off"
          {...register('zip', {
            required: ZIP_CODE_ERROR_MESSAGE,
            pattern: {
              value: ZIP_CODE_REGEX,
            },
            validate: () => {
              if (formData.city === '') return ZIP_CODE_ERROR_MESSAGE;
            },
          })}
          onChange={(e) =>
            debounceUpdate('zip', e.target.value, handleUpdateZipCode)
          }
        />
      </div>

      {isValidatingZip ? (
        <ThreeDots
          wrapperStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
          color="var(--navy-blue)"
          visible={true}
        />
      ) : (
        <input
          onClick={handleClickContinue}
          className="form-step__btn"
          disabled={Object.keys(errors).length > 0}
          type="button"
          value="Continue"
        />
      )}

      <input
        onClick={() => {
          clearErrors();
          updateStep(formData.step - 1);
        }}
        className="form-step__btn-back"
        type="button"
        value="Back"
      />
    </div>
  );
}

export default FormStep2;
