import {
  HOMEOWNER_OPTIONS,
  RADIO_ERROR_MESSAGE,
  useFormDataContext,
  useScrollToTop,
} from '../../../lib';

import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';

function FormStep1() {
  const homeownerRef = useRef();
  const { register, setValue, trigger, handleSubmit, formState } =
    useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  const { errors } = formState;

  const handleClick = (event) => {
    event.stopPropagation();
    if (event.target.type !== 'radio') {
      const input = event.currentTarget.querySelector('input[type="radio"]');
      input.click();
    }
  };

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['homeowner']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        homeowner: homeownerRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  useScrollToTop();
  return (
    <div className="form-step">
      <div className="form-step__label">Do you Own or Rent your home?</div>
      <div className="form-step__input-container">
        {HOMEOWNER_OPTIONS.map(
          ({ id, value, textOption, registerLabel }, index) => (
            <div
              key={`${id}-${index}`}
              className={`form-step__input form-step__input-no-background ${
                errors[registerLabel] && 'form-step__error'
              }`}
              onClick={handleClick}
            >
              <div className="form-step__input-radio-wrapper">
                <input
                  type="radio"
                  value={value}
                  id={id}
                  ref={homeownerRef}
                  {...register(registerLabel, { required: true })}
                  onChange={(e) => {
                    updateFormData({ homeowner: e.target.value });
                    setValue(registerLabel, e.target.value);
                  }}
                />
                <label htmlFor={id}>{textOption}</label>
              </div>
            </div>
          )
        )}
        {errors.homeowner && (
          <div className="form-step__error-message">{RADIO_ERROR_MESSAGE}</div>
        )}

        {formData.homeowner === 'yes' ? (
          <input
            onClick={handleClickContinue}
            className="form-step__btn"
            disabled={Object.keys(errors).length > 0}
            type="button"
            value="Continue"
          />
        ) : (
          <div className="form-step__error-text-label">
            Unfortunately, you must own your home to qualify for this windows
            program.
          </div>
        )}
      </div>
    </div>
  );
}

export default FormStep1;
