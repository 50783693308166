export const HOMEOWNER_OPTIONS = [
  {
    id: 'homeOwnerYes',
    value: 'yes',
    textOption: 'I own my home.',
    registerLabel: 'homeowner',
  },
  {
    id: 'homeOwnerNo',
    value: 'no',
    textOption: 'I rent my home.',
    registerLabel: 'homeowner',
  },
];

export const WINDOWS_OPTIONS = [
  {
    text: 'Replace Windows',
    value: 'replace',
  },
  {
    text: 'Repair Frame / Hardware',
    value: 'repair',
  },
];

export const WINDOWS_COUNT_OPTIONS = [
  {
    text: '10+ Windows',
    value: '10+',
  },
  {
    text: '6-9 Windows',
    value: '6-9',
  },
  {
    text: '3-5 Windows',
    value: '3-5',
  },
  {
    text: '2 Windows',
    value: '2',
  },
  {
    text: '1 Window',
    value: '1',
  },
];
