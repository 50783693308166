import {
  FIRST_NAME_ERROR_MESSAGE,
  LAST_NAME_ERROR_MESSAGE,
  NAME_REGEX,
  onlyLettersInput,
  useFormDataContext,
  useScrollToTop,
} from '../../../lib';

import { CustomInput } from '../../';
import { useFormContext } from 'react-hook-form';

function FormStep5() {
  const { register, trigger, handleSubmit, clearErrors } = useFormContext();
  const { updateFormData, formData, updateStep } = useFormDataContext();

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['address']);

    if (isValid) {
      updateStep(formData.step + 1);
    }
  });

  useScrollToTop();
  return (
    <div className="form-step3">
      <div className="form-step">
        <div className="form-step__label">Your contact information</div>

        <CustomInput
          type="text"
          placeholder="First Name"
          name="firstname"
          value={formData.firstname}
          onInput={onlyLettersInput}
          register={register}
          onInputChange={updateFormData}
          validation={{
            required: FIRST_NAME_ERROR_MESSAGE,
            pattern: {
              value: NAME_REGEX,
              message: FIRST_NAME_ERROR_MESSAGE,
            },
          }}
        />

        <CustomInput
          type="text"
          placeholder="Last Name"
          name="lastname"
          onInput={onlyLettersInput}
          register={register}
          onInputChange={updateFormData}
          validation={{
            required: LAST_NAME_ERROR_MESSAGE,
            pattern: {
              value: NAME_REGEX,
              message: LAST_NAME_ERROR_MESSAGE,
            },
          }}
        />

        <input
          onClick={handleClickContinue}
          className="form-step__btn"
          type="button"
          value="Continue"
        />

        <input
          onClick={() => {
            clearErrors();
            updateStep(formData.step - 1);
          }}
          className="form-step__btn-back"
          type="button"
          value="Back"
        />
      </div>
    </div>
  );
}

export default FormStep5;
