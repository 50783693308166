import {
  PROJECT_TYPE_ERROR_MESSAGE,
  WINDOWS_OPTIONS,
  useFormDataContext,
  useScrollToTop,
} from '../../../lib';

import { CustomSelect } from '../../';
import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';

function FormStep7() {
  const { register, trigger, handleSubmit, formState, clearErrors } =
    useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  const { errors } = formState;
  const projectTypeRef = useRef();

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['projecttype']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        projecttype: projectTypeRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  useScrollToTop();
  return (
    <div className="form-step2">
      <div className="form-step">
        <div className="form-step__label">
          Are you looking to replace or repair existing windows?
        </div>

        <CustomSelect
          options={WINDOWS_OPTIONS}
          name="projecttype"
          value={formData.projecttype}
          register={register}
          onChange={updateFormData}
          errorMessage={PROJECT_TYPE_ERROR_MESSAGE}
          errors={errors}
          customRef={projectTypeRef}
        />

        <input
          onClick={handleClickContinue}
          className="form-step__btn"
          type="button"
          value="Continue"
        />

        <input
          onClick={() => {
            clearErrors();
            updateStep(formData.step - 1);
          }}
          className="form-step__btn-back"
          type="button"
          value="Back"
        />
      </div>
    </div>
  );
}

export default FormStep7;
