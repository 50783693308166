import './topBarAndFooter.css';
import { LOCAL_PHONE_NUMBER, showELocalPhone, useLocation } from '../../lib';

import siteLogo from '../../assets/img/logo.png';

function TopBarAndFooter({ className = '' }) {
  const { postal, country } = useLocation().geoContext;

  const onClickPhoneNumber = () => {
    window.open(`tel:${LOCAL_PHONE_NUMBER}`, '_blank');
  };

  return (
    <div className={`top-bar-and-footer ${className}`}>
      <div className="top-bar-and-footer__container">
        <a href="/" className="top-bar-and-footer__link">
          <img
            src={siteLogo}
            alt="Logo"
            className="top-bar-and-footer__image"
          />
        </a>
        {showELocalPhone(postal?.code, country?.iso_code) && (
          <div
            onClick={onClickPhoneNumber}
            className="top-bar-and-footer__number-container"
          >
            {"Don't Wait - Call us now!"}
            <span className="top-bar-and-footer__number-text">
              {LOCAL_PHONE_NUMBER}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default TopBarAndFooter;
