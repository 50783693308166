import { createContext, useContext, useEffect, useState } from 'react';

import queryString from 'query-string';

const defaultState = {
  publisher_id: 1,
  subid: '',
  cake: '',
  source: process.env.REACT_APP_SOURCE,
  type: process.env.REACT_APP_TYPE,
};

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [appContext, setAppContext] = useState(defaultState);

  useEffect(() => {
    const getParameters = queryString.parse(window.location.search);
    const { publisher_id = 1, subid = '', cake = '' } = getParameters;

    setAppContext((prevState) => ({
      ...prevState,
      publisher_id,
      subid,
      cake,
    }));
  }, []);

  return (
    <AppContext.Provider value={{ appContext }}>{children}</AppContext.Provider>
  );
};
