import { useEffect } from 'react';

const useScrollToTop = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    setTimeout(scrollToTop, 100);
  }, []);
};

export default useScrollToTop;
