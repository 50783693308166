import { Helmet } from 'react-helmet-async';

function CustomPixelHelmet() {
  return (
    <Helmet>
      <script type="text/javascript">
        {`var pixelrgr_type = '${process.env.REACT_APP_TYPE}';
              var pixelrgr_source = '${process.env.REACT_APP_SOURCE}';
              var pixelrgr_url = "${process.env.REACT_APP_API_URL}";`}
      </script>
      <script
        src="https://rgr-js.s3.us-west-2.amazonaws.com/pixel.js"
        type="text/javascript"
      ></script>
    </Helmet>
  );
}

export default CustomPixelHelmet;
