import './assets/css/index.css';
import './assets/css/fonts.css';

import App from './App';
import { CombinedProviders } from './lib';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CombinedProviders>
      <App />
    </CombinedProviders>
  </React.StrictMode>
);
