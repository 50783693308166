import {
  ADDRESS_ERROR_MESSAGE,
  ADDRESS_PATTERN_ERROR_MESSAGE,
  ADDRESS_REGEX,
  CITY_ERROR_MESSAGE,
  onlyLettersInput,
  useFormDataContext,
  useScrollToTop,
} from '../../../lib';

import { CustomInput } from '../../';
import { useFormContext } from 'react-hook-form';

function FormStep4() {
  const { register, trigger, handleSubmit, clearErrors } = useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  useScrollToTop();

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['address']);

    if (isValid) {
      updateStep(formData.step + 1);
    }
  });

  return (
    <div className="form-step3">
      <div className="form-step">
        <div className="form-step__label">Your address information</div>

        <CustomInput
          type="text"
          placeholder="Street Address (no city/state/zip)"
          name="address"
          register={register}
          onInputChange={updateFormData}
          validation={{
            required: ADDRESS_ERROR_MESSAGE,
            pattern: {
              value: ADDRESS_REGEX,
              message: ADDRESS_PATTERN_ERROR_MESSAGE,
            },
          }}
        />

        <CustomInput
          type="text"
          placeholder="City"
          name="city"
          register={register}
          value={formData.city}
          onInput={onlyLettersInput}
          onInputChange={updateFormData}
          disabled={true}
          validation={{
            required: CITY_ERROR_MESSAGE,
            message: CITY_ERROR_MESSAGE,
          }}
        />

        <CustomInput
          type="text"
          placeholder="State"
          name="state"
          register={register}
          value={formData.state}
          onInput={onlyLettersInput}
          onInputChange={updateFormData}
          disabled={true}
          validation={{
            required: CITY_ERROR_MESSAGE,
            message: CITY_ERROR_MESSAGE,
          }}
        />

        <input
          onClick={handleClickContinue}
          className="form-step__btn"
          type="button"
          value="Continue"
        />

        <input
          onClick={() => {
            clearErrors();
            updateStep(formData.step - 1);
          }}
          className="form-step__btn-back"
          type="button"
          value="Back"
        />
      </div>
    </div>
  );
}

export default FormStep4;
