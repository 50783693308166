import { EXIT_PAGE_MESSAGE } from '../constants';
import { useEffect } from 'react';

function useExitConfirmation(message = EXIT_PAGE_MESSAGE) {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [message]);
}

export default useExitConfirmation;
