import debounce from 'lodash/debounce';

export const handleKeyDown = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
  }
};

export const debounceUpdate = debounce((key, value, onChange) => {
  onChange({ [key]: value });
}, 500);

export const submitForm = async (data) => {
  const trustedformtoken = `${process.env.REACT_APP_TRUSTED_FORM_API}/${window.trustedFormId}`;
  const leadidtoken = window.leadId || '';
  const anura = sessionStorage.getItem('anura_final_response');
  const tcpa =
    document.getElementById('leadid_tcpa_disclosure_label').textContent || '';
  const { _step, ...formData } = data;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...formData,
      trustedformtoken,
      leadidtoken,
      anura,
      tcpa,
    }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/lead/web`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const result = await response.json();

    return { error: false, ...result };
  } catch (error) {
    return { error: true, ...error };
  }
};
