import './assets/css/app.css';

import {
  CustomPixelHelmet,
  Disclaimer,
  MainForm,
  PageTitle,
  ThankYouAlternative,
  ThankYouLenderList,
  TopBarAndFooter,
} from './components';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
  THANK_YOU_ALTERNATIVE_ROUTE,
  THANK_YOU_LENDER_LIST_ROUTE,
  useAnura,
  useExitConfirmation,
  useJornaya,
  useTrustedForm,
} from './lib';

import React from 'react';

function App() {
  useExitConfirmation();
  useAnura();
  useJornaya();
  useTrustedForm();

  return (
    <div className="page">
      <PageTitle title={process.env.REACT_APP_TAB_NAME} />
      <CustomPixelHelmet />
      <Router>
        <div className="main-content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <TopBarAndFooter />
                  <MainForm />
                  <div>
                    <div className="disclaimer">
                      <Disclaimer />
                    </div>
                    <TopBarAndFooter className="top-bar-and-footer__bottom-gray" />
                  </div>
                </>
              }
            />

            <Route
              path={THANK_YOU_LENDER_LIST_ROUTE}
              element={
                <>
                  <TopBarAndFooter className="top-bar-and-footer__bottom-gray " />
                  <ThankYouLenderList />
                </>
              }
            />

            <Route
              path={THANK_YOU_ALTERNATIVE_ROUTE}
              element={
                <>
                  <TopBarAndFooter />
                  <ThankYouAlternative />
                </>
              }
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
