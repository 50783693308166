import {
  AppProvider,
  DisclaimerProvider,
  FormDataProvider,
  LenderProvider,
  LocationProvider,
} from './';

import { HelmetProvider } from 'react-helmet-async';

const CombinedProviders = ({ children }) => (
  <HelmetProvider>
    <LocationProvider>
      <FormDataProvider>
        <DisclaimerProvider>
          <AppProvider>
            <LenderProvider>{children}</LenderProvider>
          </AppProvider>
        </DisclaimerProvider>
      </FormDataProvider>
    </LocationProvider>
  </HelmetProvider>
);

export default CombinedProviders;
