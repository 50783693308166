import { Controller, useFormContext } from 'react-hook-form';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
  INVALID_STARTING_DIGITS_ERROR_MESSAGE,
  handleKeyDown,
} from '../../lib';
import MaskedInput from 'react-text-mask';

const MaskedInputWithRef = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.inputElement.focus();
    },
  }));

  return <MaskedInput ref={inputRef} {...props} />;
});

MaskedInputWithRef.displayName = 'MaskedInputWithRef';

function CustomPhoneInput({
  name,
  mask,
  validation = {},
  placeholder = 'Phone',
  ...props
}) {
  const { control, setError, clearErrors, setValue, formState } =
    useFormContext();
  const { errors } = formState;

  const validatePhoneFirstDigit = (value) => {
    const cleanedNumber = value.replace(/[-\s()]|_/g, '');
    if (cleanedNumber.startsWith('0') || cleanedNumber.startsWith('1')) {
      setError(name, {
        type: 'realTimeNumber',
        message: INVALID_STARTING_DIGITS_ERROR_MESSAGE,
      });
      return 'error';
    } else {
      clearErrors(name);
      return '';
    }
  };

  const handlePhoneNumber = (value) => {
    const error = validatePhoneFirstDigit(value);
    setRealTimeError(error);
  };

  const [realTimeError, setRealTimeError] = useState('');

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({ field, ref }) => (
        <MaskedInputWithRef
          {...props}
          {...field}
          onKeyDown={handleKeyDown}
          ref={ref}
          mask={mask}
          placeholder={placeholder}
          className={`form-step__input form-step__input-no-background ${
            errors[name] || realTimeError ? 'form-step__input-error' : ''
          }`}
          onChange={(e) => {
            setValue(name, e.target.value);
          }}
          onKeyUp={(e) => {
            handlePhoneNumber(e.target.value);
          }}
        />
      )}
    />
  );
}

export default CustomPhoneInput;
