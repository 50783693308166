import './mainForm.css';
import './components/steps.css';

import { FormProvider, useForm } from 'react-hook-form';
import {
  FormStep1,
  FormStep2,
  FormStep3,
  FormStep4,
  FormStep5,
  FormStep6,
  FormStep7,
} from './components';
import {
  getErrorMessage,
  submitForm,
  useAppContext,
  useFormDataContext,
  useLendersContext,
  useLocation,
} from '../../lib';

import BBBLogo from '../../assets/img/logo_bbb.png';
import { PageLoader } from '../';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function MainForm() {
  const [isLoading, setIsLoading] = useState(false);
  const { formData, clearStorage } = useFormDataContext();
  const { step } = formData;
  const { setLendersContext } = useLendersContext();
  const { location } = useLocation();
  const navigate = useNavigate();
  const methods = useForm({ defaultValues: formData, mode: 'onSubmit' });
  const { formState } = methods;
  const { errors } = formState;
  const { appContext } = useAppContext();

  const handleGlobalSubmit = async (data) => {
    setIsLoading(true);

    const result = await submitForm({ ...data, ...appContext });

    const lendersContextData = {
      name: `${formData.firstname} ${formData.lastname}`,
      zipCode: formData.zip,
    };

    if (result.status === 'matched') {
      lendersContextData.lenders = result.lenders;
    }

    if (result.pixel) {
      lendersContextData.pixel = result.pixel;
    }

    setLendersContext(lendersContextData);

    const route =
      result.status === 'matched'
        ? '/thank-you/lender-list'
        : '/thank-you/alternative';

    clearStorage();
    navigate(route);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <div className="main-form__wrapper">
            <div className="main-form__title">
              Save on replacing or repairing your windows in {location}.
            </div>
            <div className="main-form__subtitle">
              Get a free consultation...
            </div>

            <FormProvider {...methods}>
              <form
                className="main-form"
                onSubmit={methods.handleSubmit(handleGlobalSubmit)}
              >
                {!isEmpty(errors) && (
                  <div className="form-step__error-box">
                    {getErrorMessage(errors)}
                  </div>
                )}
                <div className="main-form__step-container">
                  <div className="main-form__step-info">STEP {step} of 7</div>
                  {step === 1 && <FormStep1 />}
                  {step === 2 && <FormStep2 />}
                  {step === 3 && <FormStep7 />}
                  {step === 4 && <FormStep3 />}
                  {step === 5 && <FormStep4 />}
                  {step === 6 && <FormStep5 />}
                  {step === 7 && <FormStep6 />}

                  {step !== 7 && (
                    <div className="main-form__hint">
                      <div className="main-form__hint-title">
                        Why do we ask?
                      </div>
                      <div className="main-form__hint-text">
                        This information helps us to find the best local
                        professional to complete your project.
                      </div>
                    </div>
                  )}

                  {step === 1 && (
                    <div className="main-form__img-container">
                      <img
                        className="main-form__img"
                        src={BBBLogo}
                        alt="Accredit Business"
                      />
                    </div>
                  )}
                </div>
              </form>
            </FormProvider>
          </div>
        </>
      )}
    </>
  );
}

export default MainForm;
