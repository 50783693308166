import { CustomInput, CustomPhoneInput, CustomSubmit } from '../../';
import {
  EMAIL_ERROR_MESSAGE,
  PHONE_ERROR_MESSAGE,
  US_PHONE_REGEX,
  useFormDataContext,
  useScrollToTop,
  validateEmail,
  validatePhoneNumber,
} from '../../../lib';

import { useFormContext } from 'react-hook-form';

function FormStep6() {
  const { register } = useFormContext();
  const { updateFormData } = useFormDataContext();
  useScrollToTop();
  return (
    <div className="form-step3">
      <div className="form-step">
        <div className="form-step__label">Your contact information</div>

        <CustomInput
          type="text"
          placeholder="Email Address"
          name="email"
          register={register}
          onInputChange={updateFormData}
          validation={{
            required: EMAIL_ERROR_MESSAGE,
            validate: validateEmail,
          }}
        />

        <CustomPhoneInput
          name="phone"
          placeholder="Phone (+1 is not used)"
          mask={US_PHONE_REGEX}
          autoComplete="off"
          validation={{
            required: PHONE_ERROR_MESSAGE,
            validate: validatePhoneNumber,
          }}
        />

        <CustomSubmit />
      </div>
    </div>
  );
}

export default FormStep6;
