import React, { createContext, useContext, useState } from 'react';

const defaultState = {
  lenders: [],
  name: '',
  eLocalPhone: '',
};

const defaultLenderContextValues = {
  ...defaultState,
  setLendersContext: (_updates) => {},
};

const LenderContext = createContext(defaultLenderContextValues);

export function useLendersContext() {
  return useContext(LenderContext);
}

export function LenderProvider(props) {
  const { children } = props;

  const [lendersInfo, setLendersInfo] = useState(defaultState);

  function setLendersContext(updates) {
    setLendersInfo({
      ...lendersInfo,
      ...updates,
    });
  }

  const value = {
    ...lendersInfo,
    setLendersContext,
  };

  return (
    <LenderContext.Provider value={value}>{children}</LenderContext.Provider>
  );
}
